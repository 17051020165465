import { Outlet } from "react-router-dom";
import "./style.css";
function App() {
  return (
    <div className="custom-height flex min-h-full flex-col justify-center bg-soft-green">
      <Outlet />
    </div>
  );
}

export default App;
