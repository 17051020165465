import React, { Suspense } from "react";

import ReactDOM from "react-dom/client";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import App from "./App";
import "./index.css";
import ErrorPage from "./pages/error-page";
import SubmitData from "./pages/submit-page";

import reportWebVitals from "./reportWebVitals";
import AppProvider from "./context/AppProvider";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://6405d4295eaf55b866803b5d977db92a@o4506323252477952.ingest.sentry.io/4506375106199552",
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/hitachi\.asia\/eco\-heros/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const SharingLazyLoad = React.lazy(() => import("./pages/sharing-page"));
const PublicLiveScoreLazyLoad = React.lazy(
  () => import("./pages/public-live-score")
);
const PlayerLiveScoreLazyLoad = React.lazy(
  () => import("./pages/player-live-score")
);
const PersonalizationLazyLoad = React.lazy(
  () => import("./pages/personalization-page")
);
const LayoutQuestionLazyPage = React.lazy(
  () => import("./pages/layout-question")
);
const PersonalContributionLazyLoad = React.lazy(
  () => import("./pages/personal-contribution-page")
);
const CaseStudy = React.lazy(() => import("./pages/case-study-page"));
const HomeScreenLazyLoad = React.lazy(() => import("./pages/home-screen-page"));
const GameInstructionLazyLoad = React.lazy(
  () => import("./pages/instruction-page")
);

const LayoutQuestionMobile = React.lazy(
  () => import("./pages/responsive-page/LayoutQuestionMobile")
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomeScreenLazyLoad />,
        errorElement: <ErrorPage />,
      },
      {
        path: "instruction-page",
        element: <GameInstructionLazyLoad />,
        errorElement: <ErrorPage />,
      },
      {
        path: "public-live-score",
        element: <PublicLiveScoreLazyLoad />,
        errorElement: <ErrorPage />,
      },
      {
        path: "player-live-score",
        element: <PlayerLiveScoreLazyLoad />,
        errorElement: <ErrorPage />,
      },
      {
        path: "personalization-page",
        element: <PersonalizationLazyLoad />,
        errorElement: <ErrorPage />,
      },
      {
        path: "personal-contribution-page",
        element: <PersonalContributionLazyLoad />,
        errorElement: <ErrorPage />,
      },
      {
        path: "case-study-page",
        element: <CaseStudy />,
        errorElement: <ErrorPage />,
      },
      {
        path: "sharing-page",
        element: <SharingLazyLoad />,
        errorElement: <ErrorPage />,
      },
      {
        path: "testing",
        element: <SubmitData />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/question",
    element: <LayoutQuestionLazyPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/question/:numberOfQuestion",
        element: <LayoutQuestionMobile />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Loading = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-green-500"></div>
    </div>
  );
};

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
