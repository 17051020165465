import { createClient } from "@supabase/supabase-js";
import { v4 as uuidv4 } from "uuid";
import { ANON_KEY, URL } from "../constants/config";

const supabase = createClient(URL, ANON_KEY);
const SubmitPage = () => {
    const submitScoresA = async () => {
        console.log("Btn A")
        await supabase.from("scoreboard").insert({
            id: uuidv4(),
            created_at: new Date().toISOString(),
            player: "Andreas Cromwell",
            avatar: "FEMALE_GREEN",
            score: 50,
            isFloating: true,
        });

        }

    const submitScoresB = async () => {
        console.log("Btn B")
        await supabase.from("scoreboard").insert({
            id: uuidv4(),
            created_at: new Date().toISOString(),
            player: "ABCDEFGHIJKLMN",
            avatar: "FEMALE_PINK",
            score: 500,
            isFloating: true,
        });

        await supabase.from("scoreboard").insert({
            id: uuidv4(),
            created_at: new Date().toISOString(),
            player: "Andreas Cromwell",
            avatar: "FEMALE_GREEN",
            score: 500,
            isFloating: true,
        });

        await supabase.from("scoreboard").insert({
            id: uuidv4(),
            created_at: new Date().toISOString(),
            player: "YOLO",
            avatar: "MALE_BRONZE",
            score: 500,
            isFloating: true,
        });
    }
    const deleteAllData = async () => {
        await supabase
        .from('scoreboard')
        .delete()
        .gte('score', 0);
        await supabase
        .from('scoreboard')
        .delete()
        .match({ 'player': 'ABCDEFGHIJKLMN' });

    }

    return (
        <div>
            <div>
                 <button onClick={submitScoresA}> ADD 50 SCORE</button>
            </div>
            <div>
                 <button onClick={submitScoresB}> 3 Input</button>
            </div>
            <div>
                 <button onClick={deleteAllData}> CLEAN UP DATA</button>
            </div>
        </div>
    )
}
export default SubmitPage;