import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export type AppContextState = {
  isActiveMusic: boolean;
  onChangeActiveMusic: (value: boolean) => void;
};

export const AppContext = createContext<AppContextState | null>(null);

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [isActiveMusic, setIsActiveMusic] = useState<boolean>(true);

  const handleChangeActiveMusic = useCallback((data: boolean) => {
    setIsActiveMusic(data);
  }, []);

  const value = useMemo(() => {
    return {
      isActiveMusic,
      onChangeActiveMusic: handleChangeActiveMusic,
    };
  }, [isActiveMusic, handleChangeActiveMusic]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;

export const useAppContext = () => {
  return useContext(AppContext) as AppContextState;
};
